import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { format } from "date-fns";
import toastr from "toastr";
import "toastr/build/toastr.css";
import ApiNames from "../../Constants/ApiNames";
import Axios from "./../../Utils/AxiosConfig";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay } from "swiper/modules";
import { Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { DotLoader } from "react-spinners";

const Sustainability = () => {
  const { t } = useTranslation();
  const [subscribemail, setSubscribemail] = useState("");
  const [subscribemailErr, setSubscribemailErr] = useState("");
  const [allBlogs, setAllBlogs] = useState([]);
  const [loading, setLoading] = useState(false);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "yyyy-MM-dd");
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    fetchBlogs();
  }, []);

  const handleChange = (event) => {
    if (event.target.name === "subscribemail") {
      const reg = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/i;
      if (reg.test(event.target.value)) {
        setSubscribemailErr("");
      } else {
        setSubscribemailErr("Please enter a valid email address.");
      }
      setSubscribemail(event.target.value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (subscribemail === "" || subscribemailErr !== "") {
      alert("Please fill in the input fields correctly.");
      return;
    }

    const url = ApiNames.Subscribe_Newsletter;
    const body = { email: subscribemail };

    Axios.post(url, body)
      .then((res) => {
        setSubscribemail("");
        toastr[res.data.success ? "success" : "error"](res.data.message);
      })
      .catch((error) => {
        toastr.error(error.response?.data?.message);
      });
  };

  const fetchBlogs = () => {
    setLoading(true);
    const url = ApiNames.Cerohero_AllBlogs;
    Axios.get(url)
      .then((res) => {
        setLoading(false);
        setAllBlogs(res.data.posts);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const generateSlug = (title) => {
    return title
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9\s-]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-");
  };

  const navigate = useNavigate();

  const handleNavigate = (blogData) => {
    navigate(`/home/blog/${generateSlug(blogData.title)}`, {
      state: { blogData },
    });
  };

  const override = {
    display: "block",
    margin: "20px auto",
    borderColor: "#025F5C",
  };

  return (
    <>
      <Helmet>
        <title>Sustainability</title>
      </Helmet>
      <div className="sustainability-content">
        <div className="tipsandtricks-section">
          <div className="container">
            <div className="tipsandtricks-content">
              <div className="tipsandtricks-hero">
                <h3 className="tipsandtricks-title">{t("sustainability.tipstricks")}</h3>
                <p className="tipsandtricks-subtitle">{t("sustainability.smartchoices")}</p>
              </div>

              <Swiper
                spaceBetween={30}
                slidesPerView={1}
                loop={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={false}
                navigation={false}
                modules={[Autoplay, Pagination, Navigation]}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                  },
                }}
              >
                {[
                  {
                    title: "Advocate for Change",
                    para: "Engage in community initiatives and policy advocacy to promote environmental sustainability.",
                  },
                  {
                    title: "Conscious Traveling",
                    para: "Travel sustainably by choosing eco-friendly accommodations and experiences.",
                  },
                  {
                    title: "Green Your Commute",
                    para: "Opt for public transportation, biking, or walking to reduce carbon emissions.",
                  },
                  {
                    title: "Sustainable Diet Choices",
                    para: "Prioritize plant-based meals and locally sourced foods to lessen your environmental footprint.",
                  },
                  {
                    title: "Mindful Energy Consumption",
                    para: "Switch to renewable energy sources and use appliances efficiently to conserve energy.",
                  },
                  {
                    title: "Eco-friendly Shopping",
                    para: "Choose products with minimal packaging and support eco-conscious brands.",
                  },
                  {
                    title: "Reduce, Reuse, Recycle",
                    para: "Embrace the three Rs to minimize waste and promote a circular economy.",
                  },
                  {
                    title: "Sustainable Fashion",
                    para: "Support ethical fashion brands and consider second-hand shopping to reduce waste.",
                  },
                  {
                    title: "Green Gardening",
                    para: "Cultivate a garden with native plants to support local biodiversity.",
                  },
                ].map((ary, index) => (
                  <SwiperSlide key={`slidess-${index}`}>
                    <div className="tipsandtricks-inside-content" key={ary.title}>
                      <div className="tipsandtricks-inside-image-data">
                        <h3 className="tipsandtricks-inside-image">{index + 1}</h3>
                      </div>
                      <h3 className="tipsandtricks-inside-title">{ary.title}</h3>
                      <p className="tipsandtricks-inside-para">{ary.para}</p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>

        <div className="deepdives-section">
          <div className="container">
            <div className="deepdives-content">
              <div className="deepdives-hero">
                <h3 className="deepdives-title">{t("sustainability.deepdives")}</h3>
                <p className="deepdives-subtitle">{t("sustainability.forthoseseeking")}</p>
              </div>

              {loading ? (
                <DotLoader color={"#025F5C"} cssOverride={override} loading={loading} size={100} aria-label="Loading Spinner" data-testid="loader" />
              ) : (
                <div className="row">
                  <div className="col-md-5 pe-lg-4">
                    <div className="left-data">
                      <div className="post-section">
                        <h4 className="popularr-pst">Popular posts</h4>
                        {allBlogs.slice(0, 7).map((data, index) => (
                          <div className="blogleft-deconone" onClick={() => handleNavigate(data)} key={data._id}>
                            <ul className="Post-top list-unstyled d-flex align-items-center">
                              <li className="post-left">
                                <div className="Pimage-box">
                                  <img src={data.image} alt="no-data" className="post-images" />
                                  <span className="numbers">{index + 1}</span>
                                </div>
                              </li>
                              <li className="post-right">
                                <p className="posts-content">{data.title}</p>
                                <ul className="posts-bottom list-unstyled d-flex">
                                  <li className="dat-user">
                                    <span className="icnss">
                                      <i className="far fa-clock" />
                                    </span>
                                    {formatDate(data.date)}
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 ps-lg-3">
                    <div className="right-Data">
                      {allBlogs.map((data) => (
                        <div className="linksdata-ins" onClick={() => handleNavigate(data)} key={data._id}>
                          <div className="cardsBelow">
                            <div className="cardImgs">
                              <div className="blogs-topimages">
                                <img src={data.image} alt="card" className="edit-CardIMG" />
                              </div>
                            </div>
                            <div className="card-bdy">
                              <ul className="bdy-botom list-unstyled d-flex">
                                <li className="date-user">
                                  <span className="iconss">
                                    <i className="far fa-clock" />
                                  </span>
                                  {formatDate(data.date)}
                                </li>
                                <li className="date-user">
                                  <span className="iconss">
                                    <i className="far fa-user" />
                                  </span>
                                  John Halliburton
                                </li>
                              </ul>
                              <h2 className="Cbody-tiitle">{data.title}</h2>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="joingroup-section">
          <div className="container">
            <div className="joingroup-content">
              <h3 className="join-title">{t("landing.jointhezerohero")}</h3>
              <p className="join-para">{t("landing.beazerohero")}</p>
              <p className="join-para">Download Cero Hero and begin your journey towards a balanced, conscious, and rewarding green life</p>
              {/* <ul className='join-userslist'>
                <li className='userslist-inside'><span>1M+</span>{t('landing.usersin')} <br /> {t('landing.India')}</li>
                <li className='userslist-inside'><span>500K</span>{t('landing.App')} <br />
                  {t('landing.Download')}</li>
              </ul> */}
            </div>
            <div className="become-cerohero">
              <ul className="become-lists">
                <li>
                  <div className="app-qr-data">
                    <img className="qr-image" src="../images/footer-qr.png" alt="no-data" />
                  </div>
                </li>
                <li>
                  <h3 className="become-title">{t("landing.becomeazerohero")}</h3>
                  <p className="become-para">{t("landing.dewnloadtheapp")}</p>
                </li>
                <li>
                  <div className="app-download">
                    <NavLink to="https://play.google.com/store/apps/details?id=com.cerohero" target="_blank">
                      <img className="download-image" src="../images/landing/app-store-1.png" alt="no-data" />
                    </NavLink>
                  </div>
                  <div className="app-download">
                    <NavLink to="https://apps.apple.com/in/app/cero-hero/id6460891460" target="_blank">
                      <img className="download-image" src="../images/landing/play-store-1.png" alt="no-data" />
                    </NavLink>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="subscribe-content">
          <div className="container">
            <div className="inside-subscribe">
              <h3 className="subscrib-title">
                {t("landing.subscribetoour")} <span>{t("landing.Newsletter")}</span>
              </h3>
              <p className="subscrib-para">
                {t("landing.getourfortnightly")}{" "}
                <NavLink to="/privacypolicy" className="privacy-data">
                  {t("landing.privacypolicy")}
                </NavLink>
              </p>
            </div>
            <form className="subscribe-form" onSubmit={handleSubmit}>
              <div className="row justify-content-center">
                <div className=" col-md-6">
                  <div className>
                    <input type="email" className="form-control email-data" id="email" value={subscribemail} placeholder="Your email address" name="subscribemail" onChange={handleChange} />
                    {subscribemailErr ? <p className="text-danger">{subscribemailErr}</p> : null}
                  </div>
                </div>
                <div className="col-md-1">
                  <button type="submit" className="btn btn-primary subscribe-btn">
                    {t("landing.Go")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sustainability;
