import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Helmet } from "react-helmet";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay } from "swiper/modules";
// import required modules
import { Pagination, Navigation } from "swiper/modules";
import { useTranslation } from "react-i18next";
import hero from "./../../../src/Animations/citi-anim.json";
import Axios from "./../../Utils/AxiosConfig";
import toastr from "toastr";
import "toastr/build/toastr.css";
import ApiNames from "../../Constants/ApiNames";
import RiveComponent from "@rive-app/react-canvas";
import Lottie from "lottie-react";

const Landing = () => {
  const { t } = useTranslation();
  const [isPlaying, setIsPlaying] = useState(true);
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(localStorage.getItem("isMuted") === "true" ? true : true);
  const [subscribemail, setsubscribemail] = useState("");
  const [subscribemailErr, setsubscribemailErr] = useState("");

  // const location = useLocation();
  // useEffect(() => {
  //   // const hash = window.location.hash;
  //   const hash = location.hash;
  //   console.log("------",hash);
  //   if (hash) {
  //     const newId = hash.substring(1);
  //     setId(newId);
  //   } else {
  //     setId(''); // Reset ID if hash is not present
  //   }
  // }, [location.hash]); // Run effect whenever window.location.hash changes

  // useEffect(() => {
  //   if (id) {
  //     const element = document.getElementById(id);
  //     if (element) {
  //       element.scrollIntoView({
  //         behavior: 'smooth'
  //       });
  //     }
  //   }
  // }, [id]); // Run effect whenever the ID changes

  // const togglePlayPause = () => {
  //   const video = videoRef.current;

  //   if (video.paused || video.ended) {
  //     video.play();
  //   } else {
  //     video.pause();
  //   }

  //   setIsPlaying(!isPlaying);
  // };
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Trigger when at least 50% of the element is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Play the video when it's in the viewport
          if (isPlaying) {
            videoRef.current.play();
          }
        } else {
          // Pause the video when it's out of the viewport
          videoRef.current.pause();
        }
      });
    }, options);

    observer.observe(videoRef.current);

    // Cleanup
    return () => observer.disconnect();
  }, [isPlaying]);

  useEffect(() => {
    // Set mute state
    videoRef.current.muted = isMuted;
    // Store mute state in localStorage
    localStorage.setItem("isMuted", isMuted);
  }, [isMuted]);

  const togglePlayPause = () => {
    const video = videoRef.current;

    if (video.paused || video.ended) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const handleChange = (event) => {
    // console.log("---->", event.target.name, event.target.value);
    if (event.target.name === "subscribemail") {
      let reg = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/i;
      if (reg.test(event.target.value)) {
        setsubscribemailErr("");
      } else {
        setsubscribemailErr("please enter correct email");
      }
      setsubscribemail(event.target.value);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (subscribemail === "" || subscribemailErr !== "") {
      alert("Please fill input fields");
      return;
    } // api call

    const url = ApiNames.Subscribe_Newsletter;
    const body = {
      email: subscribemail,
    };

    Axios.post(url, body)
      .then((res) => {
        if (res.data.success === true) {
          setsubscribemail("");

          toastr.success(res.data.message);
        } else {
          setsubscribemail("");
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        toastr.error(error.response?.data?.message);
      });
  };
  // scrollspy
  // useEffect(() => {
  //  const hash = location.hash;
  //  if(hash){
  //   const id =hash.substring(1);
  //   const element = document.getElementById(id);
  //   if(element){
  //     element.scrollIntoView({
  //       behavior:"smooth"
  //     });
  //   }
  //  }

  // }, [location.hash])

  const goalsData = [
    { imageSrc: "../images/landing/un-1.png", altText: "no-data", goalText: "NO POVERTY" },
    { imageSrc: "../images/landing/un-2.png", altText: "no-data", goalText: "ZERO HUNGER" },
    { imageSrc: "../images/landing/un-3.png", altText: "no-data", goalText: "GOOD HEALTH AND WELL-BEING" },
    { imageSrc: "../images/landing/un-4.png", altText: "no-data", goalText: "QUALITY EDUCATION" },
    { imageSrc: "../images/landing/un-5.png", altText: "no-data", goalText: "GENDER EQUALITY" },
    { imageSrc: "../images/landing/un-6.png", altText: "no-data", goalText: "CLEAN WATER & SANITATION" },
    { imageSrc: "../images/landing/un-7.png", altText: "no-data", goalText: "AFFORDABLE AND CLEAN ENERGY" },
    { imageSrc: "../images/landing/un-8.png", altText: "no-data", goalText: "DECENT WORK & ECONOMIC GROWTH" },
    { imageSrc: "../images/landing/un-9.png", altText: "no-data", goalText: "INDUSTRY, INNOVATION & INFRASTRUCTURE" },
    { imageSrc: "../images/landing/un-10.png", altText: "no-data", goalText: "REDUCED INEQUALITIES" },
    { imageSrc: "../images/landing/un-11.png", altText: "no-data", goalText: "SUSTAINABLE CITIES & COMMUNITIES" },
    { imageSrc: "../images/landing/un-12.png", altText: "no-data", goalText: "RESPONSIBLE CONSUMPTION & PRODUCTION" },
    { imageSrc: "../images/landing/un-13.png", altText: "no-data", goalText: "CLIMATE ACTION" },
    { imageSrc: "../images/landing/un-14.png", altText: "no-data", goalText: "LIFE BELOW WATER" },
    { imageSrc: "../images/landing/un-15.png", altText: "no-data", goalText: "LIFE ON EARTH" },
    { imageSrc: "../images/landing/un-16.png", altText: "no-data", goalText: "PEACE, JUSTICE & STRONG INSTITUTIONS" },
    { imageSrc: "../images/landing/un-17.png", altText: "no-data", goalText: "PARTNERSHIPS FOR THE GOALS" },
    { imageSrc: "../images/landing/un-18.png", altText: "no-data", goalText: "SUSTAINABLE DEVELOPMENT GOALS" },
  ];

  return (
    <>
      <Helmet>
        <title>Cero Hero: Track, Offset, and Sustain with Every Action</title>
      </Helmet>
      <div className="landing-content">
        <div className="tophero-section">
          {/* <div className='container-fluid'> */}
          <div className="insidehero-section">
            {/* <h3 className='empower-title'>{t('landing.empowerjourney')} <br/> <span>{t('landing.ceroherologo')}</span>.</h3>
              <p className='empower-subtile'>{t('landing.unlockfuture')} </p>
              <NavLink target='_blank' to={getDynamicDestination()} className="btn join-button">{t('landing.joinmovement')}</NavLink> */}
            <Lottie className="image-data" animationData={hero} loop={true} />

            {/* <div className="banner">
  <div
    className="lottie-animation"
    data-w-id="cddd1c7e-a951-cb85-3915-1aca5e9a0085"
    data-animation-type="lottie"
    data-src="https://zeroheros-blog.s3.ap-south-1.amazonaws.com/Json-purpose/data.json"
    data-loop={1}
    data-direction={1}
    data-autoplay={1}
    data-is-ix2-target={0}
    data-renderer="canvas"
    data-default-duration="30.030028806881905"
    data-duration={0}
  >
    <canvas
      width={2280}
      height={1000}
      style={{ width: "100%", height: "100%", transformOrigin: "0px 0px 0px" }}
    />
  </div>
</div> */}
          </div>
          {/* </div> */}
        </div>

        <div className="container">
          <h3 className="meet-title">
            Embrace a Net-Zero Lifestyle <br /> Join the Cero Hero Green Revolution!
          </h3>

          <div className="herovideo-section">
            <video className="videotag-data" ref={videoRef} loop muted={isMuted}>
              <source className="videosection-inside" src="../video/greenvideo.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="playpause-btn" style={{ marginTop: "10px" }}>
              <button className="buttons-data" onClick={togglePlayPause}>
                {isPlaying ? <img className="image-btns" src="../images/landing/pause.svg" alt="Pause" /> : <img className="image-btns" src="../images/landing/play.svg" alt="Play" />}
              </button>
              <button className="buttons-data" onClick={toggleMute}>
                {isMuted ? <img className="image-btns" src="../images/landing/mute.svg" alt="Unmute" /> : <img className="image-btns" src="../images/landing/unmute.svg" alt="Mute" />}
              </button>
            </div>
          </div>
        </div>
        <div className="meet-section">
          <div className="container">
            <div className="meetinside-content">
              <h3 className="meet-title">
                {t("landing.meetyour")} <br /> {t("landing.companioninsustainability")}
              </h3>
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="meet-left">
                    <p className="meet-paras">{t("landing.navigationpath")} </p>
                    <p className="meet-paras">
                      {t("landing.thatswhere")} <span>{t("landing.ceroheros")}</span> {t("landing.stepsin")}
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="meet-right">
                    <img className="image-data" src="../images/landing/meetphone.webp" alt="cero hero app screens" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ourmission-section">
          <div className="container">
            <div className="ourmission-yourhands">
              <div className="ourmission-inside">
                <h3 className="ourmission-title">{t("landing.ourmission")}</h3>
                <p className="ourmission-para">{t("landing.makingsustainability")}</p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-4">
                <div className="making-lists">
                  <div className="logo-list">
                    <img className="making-list-image" src="../images/landing/ourmission-logo-1.svg" alt="no-data" />
                  </div>
                  <h3 className="makinglist-title">{t("landing.personalizationgoals")}</h3>
                  <p className="makinglist-para">{t("landing.tailoringyoursus")}</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="making-lists">
                  <div className="logo-list">
                    <img className="making-list-image" src="../images/landing/ourmission-logo-2.svg" alt="no-data" />
                  </div>
                  <h3 className="makinglist-title">{t("landing.actionableinsights")}</h3>
                  <p className="makinglist-para">{t("landing.offeringtangible")}</p>
                </div>
              </div>

              <div className="col-md-4">
                <div className="making-lists">
                  <div className="logo-list">
                    <img className="making-list-image" src="../images/landing/ourmission-logo-3.svg" alt="no-data" />
                  </div>
                  <h3 className="makinglist-title">{t("landing.rewardingyour")}</h3>
                  <p className="makinglist-para">{t("landing.celebratingevery")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="meenforyou-section">
          <div className="container">
            <div className="meenforyou-content">
              <h3 className="meenforyou-title">{t("landing.whatthis")}</h3>
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="meenforyou-right">
                    {/* <img className='image-data' src='../images/landing/meantoyou.webp' alt='CeroHero empower indiviuals towards net zero carbon free lifestyle' /> */}
                    <RiveComponent className="image-data" style={{ width: "400px !important", height: "400px" }} src="/images/animations/planet_animation.riv" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="meenforyou-left">
                    <h3 className="meenforyou-subtitle">{t("landing.embarkona")}</h3>
                    <p className="meenforyou-paras">
                      {t("landing.bychoosing")} <span>{t("landing.ceroheros")}</span>, {t("landing.yourenotjust")}
                    </p>
                    <p className="meenforyou-paras">
                      {t("landing.becomea")} <span>{t("landing.ceroheros")}</span> {t("landing.todayandcatalyze")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="everydaymission-section">
          <div className="container">
            <div className="everydaymission-yourhands">
              <div className="everydaymission-inside">
                <h3 className="everydaymission-title">{t("landing.everydaymission")}</h3>
                <p className="everydaymission-para">{t("landing.turnyourdaily")}</p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-12">
                <Swiper
                  pagination={false}
                  navigation={false}
                  loop={true}
                  spaceBetween={30}
                  centeredSlides={true} // Center the active slide
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                    waitForTransition: true,
                  }}
                  speed={1000}
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                    1024: {
                      slidesPerView: 4,
                    },
                  }}
                  modules={[Pagination, Navigation, Autoplay]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <div className="mission-swipers">
                      <div className="mission-images">
                        <img className="mission-inside-image" src="../images/landing/mission-1.webp" alt="no-data" />
                      </div>
                      <h3 className="mission-swiper-title">{t("landing.Composting")}</h3>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="mission-swipers">
                      <div className="mission-images">
                        <img className="mission-inside-image" src="../images/landing/mission-2.webp" alt="no-data" />
                      </div>
                      <h3 className="mission-swiper-title">{t("landing.conservewater")}</h3>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="mission-swipers">
                      <div className="mission-images">
                        <img className="mission-inside-image" src="../images/landing/mission-3.webp" alt="no-data" />
                      </div>
                      <h3 className="mission-swiper-title">{t("landing.ledlighting")}</h3>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="mission-swipers">
                      <div className="mission-images">
                        <img className="mission-inside-image" src="../images/landing/mission-4.webp" alt="no-data" />
                      </div>
                      <h3 className="mission-swiper-title">{t("landing.planttree")}</h3>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="mission-swipers">
                      <div className="mission-images">
                        <img className="mission-inside-image" src="../images/landing/mission-5.webp" alt="no-data" />
                      </div>
                      <h3 className="mission-swiper-title">{t("landing.recycling")}</h3>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="mission-swipers">
                      <div className="mission-images">
                        <img className="mission-inside-image" src="../images/landing/mission-6.png" alt="no-data" />
                      </div>
                      <h3 className="mission-swiper-title">Eco-Friendly</h3>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="mission-swipers">
                      <div className="mission-images">
                        <img className="mission-inside-image" src="../images/landing/mission-7.png" alt="no-data" />
                      </div>
                      <h3 className="mission-swiper-title">Save Electricity</h3>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="mission-swipers">
                      <div className="mission-images">
                        <img className="mission-inside-image" src="../images/landing/mission-8.png" alt="no-data" />
                      </div>
                      <h3 className="mission-swiper-title">Organic Eating</h3>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
        <div className="advanture-section">
          <div className="container">
            <h3 className="advanture-title">
              {t("landing.embarkingona")} <br /> {t("landing.adventurewithcerohero")}
            </h3>
            <div className="adventure-inside">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="advanture-left">
                    <div className="inside-left">
                      <h3 className="advantureinside-title">{t("landing.setyourgreen")}</h3>
                      <p className="advanture-para">{t("landing.tailoryour")}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="advanture-right">
                    {/* <img className='advanture-image' src='../images/landing/advanture-1.webp' alt='tailor your sustainability aspirations with personalized, achievable objectives with CeroHero' /> */}
                    <RiveComponent className="advanture-image" style={{ width: "400px !important", height: "400px" }} src="/images/animations/girl.riv" />
                  </div>
                </div>
              </div>
            </div>
            <div className="adventure-inside">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="advanture-right">
                    <RiveComponent className="advanture-image" style={{ width: "400px !important", height: "400px" }} src="/images/animations/bicycle.riv" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="advanture-left">
                    <div className="inside-left">
                      <h3 className="advantureinside-title">{t("landing.engageindaily")}</h3>
                      <p className="advanture-para">{t("landing.transformeveryday")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="adventure-inside mb-0">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="advanture-left">
                    <div className="inside-left">
                      <h3 className="advantureinside-title">{t("landing.reapyourreward")}</h3>
                      <p className="advanture-para">{t("landing.celebrateeverygreen")}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="advanture-right">
                    <RiveComponent className="advanture-image" style={{ width: "400px !important", height: "400px" }} src="/images/animations/beeandflower.riv" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="meenforyou-section">
          <div className="container">
            <div className="meenforyou-content">
              <h3 className="meenforyou-title meenforyou-title1">{t("landing.unitednations")}</h3>
              <h3 className="meenforyou-title ">{t("landing.unitednations1")}</h3>
              <div className="row justify-content-center">
                <div className="col-md-6">
                  {/* <div className='meenforyou-right'>
                    <img className='image-data' src='../images/landing/meantoyou.webp' alt='CeroHero empower indiviuals towards net zero carbon free lifestyle' />

                  </div> */}
                  <div className="united-nations-data">
                    <ul className="unitednation-ul">
                      {goalsData.map((goal, index) => (
                        <li className="unitednation-li" key={index}>
                          <div className="card">
                            <div className="side front">
                              <div className="flip-card-front">
                                <img className="flipcard-image" src={goal.imageSrc} alt={goal.altText} />
                              </div>
                            </div>
                            <div className="side back">
                              <div className="flip-card-back">
                                <h1 className="united-text">{goal.goalText}</h1>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="meenforyou-left">
                    <p className="meenforyou-paras meenforyou-paras1">{t("landing.atceroheroststain")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="customerslist-section">
          <div className="container">
            <div className="customerslist-content">
              <div className="customerslist">
                <div className="customerslist-title">
                  <h1>Partners & Customers</h1>
                </div>
                <div className="customers-imagecard">
                  <img src="../images/landing/microsoft-startups-logo.png" alt="no-data" />
                  <img src="../images/landing/nordic-plus-logo.png" alt="no-data" />
                  <img src="../images/landing/its-finland-logo.png" alt="no-data" />
                  {/* <img src="../images/landing/rebound-logo.png" alt="no-data" /> */}
                  <img src="../images/landing/urbantech-logo.png" alt="no-data" />
                  <img src="../images/landing/maria01-logo.png" alt="no-data" />
                  <img src="../images/landing/aalto-logo.png" alt="no-data" />
                  <img src="../images/landing/grad-logo.png" alt="no-data" />
                  <img src="../images/landing/farms-logo.png" alt="no-data" />
                  <img src="../images/landing/espoo-logo.png" alt="no-data" />
                  <img src="../images/landing/enterespoo-logo.png" alt="no-data" />
                  <img src="../images/landing/Business-Finland-logo.png" alt="no-data" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="joingroup-section">
          <div className="container">
            <div className="joingroup-content">
              <h3 className="join-title">{t("landing.jointhezerohero")}</h3>
              <p className="join-para">{t("landing.beazerohero")}</p>
              <p className="join-para">Download Cero Hero and begin your journey towards a balanced, conscious, and rewarding green life</p>
              {/* <ul className='join-userslist'>
                <li className='userslist-inside'><span>1M+</span>{t('landing.usersin')} <br /> {t('landing.India')}</li>
                <li className='userslist-inside'><span>500K</span>{t('landing.App')} <br />
                  {t('landing.Download')}</li>
              </ul> */}
            </div>
            <div className="become-cerohero">
              <ul className="become-lists">
                <li>
                  <div className="app-qr-data">
                    <img className="qr-image" src="../images/footer-qr.png" alt="no-data" />
                  </div>
                </li>
                <li>
                  <h3 className="become-title">{t("landing.becomeazerohero")}</h3>
                  <p className="become-para">{t("landing.dewnloadtheapp")}</p>
                </li>
                <li>
                  <div className="app-download">
                    <NavLink to="https://play.google.com/store/apps/details?id=com.cerohero" target="_blank">
                      <img className="download-image" src="../images/landing/app-store-1.png" alt="no-data" />
                    </NavLink>
                  </div>
                  <div className="app-download">
                    <NavLink to="https://apps.apple.com/in/app/cero-hero/id6460891460" target="_blank">
                      <img className="download-image" src="../images/landing/play-store-1.png" alt="no-data" />
                    </NavLink>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="subscribe-content">
          <div className="container">
            <div className="inside-subscribe">
              <h3 className="subscrib-title">
                {t("landing.subscribetoour")} <span>{t("landing.Newsletter")}</span>
              </h3>
              <p className="subscrib-para">
                {t("landing.getourfortnightly")}{" "}
                <NavLink to="/privacypolicy" className="privacy-data">
                  {t("landing.privacypolicy")}
                </NavLink>
              </p>
            </div>
            <form className="subscribe-form" onSubmit={handleSubmit}>
              <div className="row justify-content-center">
                <div className=" col-md-6">
                  <div className>
                    <input type="email" className="form-control email-data" id="email" value={subscribemail} placeholder="Your email address" name="subscribemail" onChange={handleChange} />
                    {subscribemailErr ? <p className="text-danger">{subscribemailErr}</p> : null}
                  </div>
                </div>
                <div className="col-md-1">
                  <button type="submit" className="btn btn-primary subscribe-btn">
                    {t("landing.Go")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
