// import React, { useState } from 'react';
// import Lottie from 'lottie-react';
// import hero from "./../../../src/Animations/citi-anim.json";
// import { NavLink } from "react-router-dom";

// const Welcomepage = () => {
//   const [activeButton, setActiveButton] = useState('individuals');

//   return (
//     <div className='welcome-section'>
//       <div className='topwelcome-section'>
//         <div className="cerohero-logo-ws">
//           <img
//             className="ws-cerohero-logo"
//             src="../images/welcome/cerohero-logo.png"
//             alt="Cero Hero logo"
//           />
//         </div>
//         <div className='insidewelcome-section'>
//           <Lottie
//             className="image-data"
//             animationData={hero}
//             loop={true}
//           />
//         </div>
//         <div className='button-section'>
//           <NavLink to="/home/contactus" className="nav-link">
//             <button
//               className={`button ${activeButton === 'enterprises' ? 'active' : ''}`}
//               onMouseEnter={() => setActiveButton('enterprises')}
//               onClick={() => setActiveButton('enterprises')}
//             >
//               ENTERPRISES <span><img src={activeButton === 'enterprises' ? "../images/welcome/green-arrow.png" : "../images/welcome/black-arrow.png"} alt="arw" /></span>
//             </button>
//           </NavLink>
//           <NavLink to="/home" className="nav-link">
//             <button
//               className={`button ${activeButton === 'individuals' ? 'active' : ''}`}
//               onMouseEnter={() => setActiveButton('individuals')}
//               onClick={() => setActiveButton('individuals')}
//             >
//               INDIVIDUALS <span><img src={activeButton === 'individuals' ? "../images/welcome/green-arrow.png" : "../images/welcome/black-arrow.png"} alt="arw" /></span>
//             </button>
//           </NavLink>
//         </div>

//       </div>
//     </div>
//   );
// }

// export default Welcomepage;

import React, { useState } from "react";
import Lottie from "lottie-react";
import hero from "./../../../src/Animations/citi-anim.json";
import { NavLink } from "react-router-dom";

const Welcomepage = () => {
  const [activeButton, setActiveButton] = useState("individuals");

  const handleMouseEnter = (buttonType) => {
    setActiveButton(buttonType);
  };

  const handleTouchStart = (buttonType) => {
    setActiveButton(buttonType);
  };

  return (
    <div className="welcome-section">
      <div className="topwelcome-section">
        <div className="cerohero-logo-ws">
          <img className="ws-cerohero-logo" src="../images/welcome/cerohero-logo.png" alt="Cero Hero logo" />
        </div>
        <div className="insidewelcome-section">
          <Lottie className="image-data" animationData={hero} loop={true} />
        </div>
        <div className="button-section">
          <NavLink to="/enterprisehome" className="nav-link">
            <button
              className={`button ${activeButton === "enterprises" ? "active" : ""}`}
              onMouseEnter={() => handleMouseEnter("enterprises")}
              onTouchStart={() => handleTouchStart("enterprises")}
              onClick={() => setActiveButton("enterprises")}
            >
              ENTERPRISES{" "}
              <span>
                <img src={activeButton === "enterprises" ? "../images/welcome/green-arrow.png" : "../images/welcome/black-arrow.png"} alt="arw" />
              </span>
            </button>
          </NavLink>
          <NavLink to="/home" className="nav-link">
            <button
              className={`button ${activeButton === "individuals" ? "active" : ""}`}
              onMouseEnter={() => handleMouseEnter("individuals")}
              onTouchStart={() => handleTouchStart("individuals")}
              onClick={() => setActiveButton("individuals")}
            >
              INDIVIDUALS{" "}
              <span>
                <img src={activeButton === "individuals" ? "../images/welcome/green-arrow.png" : "../images/welcome/black-arrow.png"} alt="arw" />
              </span>
            </button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Welcomepage;
