import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

const CarbonFootprint = (data) => {
  const [emissions, setEmissions] = useState()
  useEffect(() => {
    try {
      let getInfo = async () => {
        let data = await localStorage.getItem("carbonEmission")
        
        setEmissions(data)
      }
      getInfo()
    } catch (err) {

    }
  }, [])

  return (
    <>
     <div className="calculations-content">
        <div className="container">
          <ul className="calcularor-navbar">
            <li>
              <NavLink to={"/"}><div className="navbar-image"><img className="image-data" src="./images/navbar/navbar-logo-black.png" alt="no-data" />
              </div></NavLink>
            </li>
          </ul>
          <div className='doyouknow-content'>
            <div className='row'>
            <div className='col-md-6'>
                <div className='doyouknow-content-inside'>
                  <div className='doyouknow-content-right'>
                  <img className="image-data" src="./images/calculator/carbonfootprint.webp" alt="no-data" />
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='doyouknow-content-inside'>
                  <div className='doyouknow-content-left'>
          
                    <h3 className='doyouknow-value doyouknow-value1'>{emissions}</h3>
                    <p className='carbon-value'>Kg CO2e /year</p>
                    <p className='doyouknow-para doyouknow-para1'>😊 Thanks for calculating your carbon footprint! Let's stay committed to sustainability. Keep optimizing for a greener future. Your actions matter!</p>

                    <ul className='previous-nextbtns'>
                   
                      <li>
                        <NavLink to={"/home"} className="next-btn">Done</NavLink>
                      </li>
                    </ul>

                  </div>
                </div>
              </div>
            

            </div>

          </div>


        </div>
      </div>
    </>
  )
}

export default CarbonFootprint