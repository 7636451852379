import React, { useState, useEffect, useReducer, useRef } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const Header = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [navMenuActive, setNavMenuActive] = useState(false);
  const [servicesDropdownActive, setServicesDropdownActive] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    // Set the language without refreshing the page
    const storedLanguage = localStorage.getItem("i18nextLng");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  useEffect(() => {
    const handleNavItemClick = (event) => {
      const target = event.currentTarget;
      const isDropdownToggle = target.classList.contains("dropdown-toggle");

      if (isDropdownToggle) {
        event.preventDefault(); // Prevent default behavior for dropdown toggles

        // Toggle dropdown logic here if needed
        setServicesDropdownActive(!servicesDropdownActive);
      } else {
        const parentDropdown = target.closest(".dropdown-menu");

        if (parentDropdown) {
          // Toggle parent dropdown logic here if needed
        } else {
          setNavMenuActive(false); // Hide mobile navigation menu
        }
      }
    };

    const navLinks = document.querySelectorAll(".navbar-collapse a");
    navLinks.forEach((link) => {
      link.addEventListener("click", handleNavItemClick);
    });

    return () => {
      navLinks.forEach((link) => {
        link.removeEventListener("click", handleNavItemClick);
      });
    };
  }, [servicesDropdownActive]);

  const handleHamburgerClick = () => {
    setNavMenuActive(!navMenuActive);
  };

  // Load initial theme preference from localStorage
  useEffect(() => {
    const storedTheme = localStorage.getItem("darkMode");
    setIsDarkMode(storedTheme ? JSON.parse(storedTheme) : false);
  }, []);

  const toggleTheme = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  // Update theme in localStorage and apply to the body
  useEffect(() => {
    document.body.classList.toggle("dark-theme", isDarkMode);
    localStorage.setItem("darkMode", JSON.stringify(isDarkMode));
  }, [isDarkMode]);

  const isAndroid = () => {
    return navigator.userAgent.toLowerCase().indexOf("android") > -1;
  };

  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  };

  const getDynamicDestination = () => {
    const userAgent = window.navigator.userAgent;

    // Detect if the user is using Windows
    const isWindows = /Windows/.test(userAgent);

    if (isWindows) {
      // Redirect to the Microsoft Store
      return "https://play.google.com/store/apps/details?id=com.cerohero&pli=1";
    } else if (isAndroid()) {
      // Dynamic link for Android
      return "https://play.google.com/store/apps/details?id=com.cerohero&pli=1";
    } else if (isIOS()) {
      // Dynamic link for iOS
      return "https://apps.apple.com/in/app/cero-hero/id6460891460";
    } else {
      // Default destination if not Windows, Android, or iOS
      return "https://apps.apple.com/in/app/cero-hero/id6460891460";
    }
  };

  let clickNav = useRef(null);
  function closemenu() {
    if (clickNav.current) {
      clickNav.current.click();
    }
  }

  return (
    <header>
      <nav className={`navbar navbar-expand-lg navbar-light TopnavBar-Section p-2`}>
        <div id="progress-barss"></div>
        <div className="container">
          <NavLink className="navbar-brand logo-section" to="/home">
            <img className="main-logo" src={isDarkMode ? "/images/navbar/navbar-logo-white.png" : "/images/navbar/navbar-logo-black.png"} loading="eager" alt="Cero Hero logo" />
          </NavLink>
          <form className="form-inline d-block d-lg-none">
            <NavLink className="btn login-txt" target="_blank" to={getDynamicDestination()} type="submit">
              {t("navbar.download")}
            </NavLink>
            {/* <NavLink to="#" type="button" className="mode-change" onClick={toggleTheme}>

            <img
              className="main-logo"
              src={isDarkMode ? '/images/navbar/black.png' : '/images/navbar/white.png'}
              loading="eager"
              alt=" Cero Hero logo"
            />

            </NavLink> */}
          </form>
          <div ref={clickNav} className={`hamburger-edit ${navMenuActive ? "active" : ""}`} onClick={handleHamburgerClick}>
            <span className="bar" />
            <span className="bar" />
            <span className="bar" />
          </div>
          <div className={`navbar-collapse navbarTop-colapse ${navMenuActive ? "active" : ""}`}>
            <ul className="navbar-nav mr-auto navbarTopUl-edit p-2">
              <li className="nav-item">
                <NavLink className="nav-link navlink-mainlist login-txt" to={"/personal-carbon-footprint-calculator"}>
                  {t("navbar.impactcalculater")}
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink className="nav-link navlink-mainlist " to={"/home/enterprise"}>
                  {t('navbar.enterprise')}
                </NavLink>
              </li> */}

              <li className="nav-item dropdown">
                <NavLink className="nav-link navlink-mainlist dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Sustainability
                </NavLink>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink className="dropdown-item navlink-mainlist" onClick={closemenu} to="/home/sustainable-learning-hub">
                      {t("navbar.sustainability")}
                    </NavLink>
                  </li>
                </ul>
              </li>
              {/* <li className="nav-item ">
                <NavLink className="nav-link navlink-mainlist" to={"/cerohero_in_action"}>
                  {t('navbar.inaction')}
                </NavLink>
              </li> */}
              <li className="nav-item dropdown">
                <NavLink className="nav-link navlink-mainlist dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Action
                </NavLink>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink className="dropdown-item navlink-mainlist" onClick={closemenu} to="/home/cero-hero-community">
                      {t("navbar.inaction")}
                    </NavLink>
                  </li>
                </ul>
              </li>
              {/* <li className="nav-item ">
                <NavLink className="nav-link navlink-mainlist" to={"/rewards"}>
                  {t('navbar.reward')}
                </NavLink>
              </li> */}
              <li className="nav-item dropdown">
                <NavLink className="nav-link navlink-mainlist dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {t("navbar.reward")}
                </NavLink>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink className="dropdown-item navlink-mainlist" onClick={closemenu} to={"/home/marketplace"}>
                      {t("navbar.reward")}
                    </NavLink>
                  </li>
                </ul>
              </li>
              {/* <li className="nav-item ">
                <NavLink className="nav-link navlink-mainlist" to={"/ourway"}>
                  {t('navbar.ourway')}
                </NavLink>
              </li> */}
              <li className="nav-item dropdown">
                <NavLink className="nav-link navlink-mainlist dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {t("navbar.ourway")}
                </NavLink>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink className="dropdown-item navlink-mainlist" onClick={closemenu} to="/home/about-us">
                      {t("navbar.ourway")}
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink className="dropdown-item navlink-mainlist" onClick={closemenu} to="/media">
                      {t('navbar.media')}
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink className="dropdown-item navlink-mainlist" onClick={closemenu} to="/home/advisory">
                      {t("navbar.advisory")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item navlink-mainlist" onClick={closemenu} to={"/home/partners"}>
                      {t("navbar.partners")}
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink className="dropdown-item navlink-mainlist" to="/team">
                      {t('navbar.team')}
                    </NavLink>
                  </li> */}
                </ul>
              </li>
              {/* <li className="nav-item">
                <NavLink className="nav-link navlink-mainlist " to={"/enterprise"}>
                  {t('navbar.enterprise')}
                </NavLink>
              </li> */}

              {/* <li className="nav-item dropdown">
                <div className="dropdown relvence-Ddown">
                  <NavLink
                    className="btn btn-secondary dropdown-toggle relvence-Inside"
                    href=""
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="upDwn-arrw">

                      <span className="relvance-filterTitle">Filter</span>
                    </span>
                    <span className="upArrow-Icn">
                      <i className="fas fa-angle-down arwUpIcon"></i>
                    </span>
                  </NavLink>

                  <ul className="dropdown-menu Relvence-Top">
                    <li>
                      <NavLink className="dropdown-item relvence-Dropitems">
                        Relevance
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item relvence-Dropitems">
                        Recomended
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item relvence-Dropitems">
                        Something else here
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li> */}

              {/* <li className="nav-item ">
                <div className="language-dropdown">
                  <select value={i18n.language} onChange={(e) => changeLanguage(e.target.value)}>
                    <option value="en">English</option>
                    <option value="hi">Hindi</option>
                    <option value="th">Thai</option>

                  </select>
                </div>

              </li> */}
            </ul>
            <form className="form-inline d-none d-lg-block">
              <NavLink className="btn login-txt" type="button" target="_blank" to={getDynamicDestination()}>
                {t("navbar.download")}
              </NavLink>
              {/* <NavLink to="#" type="button" className="mode-change" onClick={toggleTheme}>
                <img
              className="main-logo"
              src={isDarkMode ? '/images/navbar/black.png' : '../images/navbar/white.png'}
              loading="eager"
              alt=" Cero Hero logo"
            />

              </NavLink> */}
            </form>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
