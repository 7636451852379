import React, { useRef } from "react";
import Header from "../Common/Header/Header";
import { Outlet } from "react-router-dom";
import Footer from "../Common/Footer/Footer";

const Layout = () => {
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  console.log("===========", section1Ref);

  const scrollToSection = (sectionId) => {
    let ref;
    console.log(sectionId);
    switch (sectionId) {
      case "section1":
        ref = section1Ref;
        break;
      case "section2":
        ref = section2Ref;
        break;
      case "section3":
        ref = section3Ref;
        break;
      default:
        return;
    }
    console.log(section1Ref);
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const gotoWhatsapp = () => {
    let url = "https://api.whatsapp.com/send?phone=971567684557&text=Hello";
    window.open(url, "_blank");
  };
  return (
    <>
      <img onClick={gotoWhatsapp} src="/images/WhatsApp_icon.png" className="WhatsappIcon" alt="logo" />
      {/* <i  onClick={gotoWhatsapp} className="fa-brands fa-whatsapp WhatsappIcon"></i> */}
      {/* <img src="/images/navbar/what.png" className="WhatsappIcon" alt="logo" /> */}
      {/* <button onClick={gotoWhatsapp} className='WhatsappIcon'>Whatspp</button> */}
      <Header />
      <Outlet />
      <Footer scrollToSection={scrollToSection} />
    </>
  );
};

export default Layout;
