import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Navigation, Autoplay } from "swiper/modules";
import { NavLink } from "react-router-dom";

const Enterprisepage = () => {
  const logos = [
    "../images/enterprisepage/1.png",
    "../images/enterprisepage/2.png",
    "../images/enterprisepage/3.png",
    "../images/enterprisepage/4.png",
    "../images/enterprisepage/5.png",
    "../images/enterprisepage/its-finland-logo.png",
  ];
  return (
    <div className="landing-page">
      {/* Hero Section */}
      <div className="hero-section">
        {/* <img src="..../images/enterprisepage/wave11.png" alt="" className="layer1" /> */}
        <div className="content">
          <h1 className="unlock-carbon">
            Empowering Enterprises, Organizations, and Communities for a <span>Sustainable</span> Future
          </h1>
          <p className="hero-para">
            Our automated carbon accounting software is designed to deliver actionable insights, drive measurable sustainability outcomes, and streamline compliance with environmental standards for
            enterprises, organizations, and communities committed to impactful climate action.
          </p>
          <div className="cta-buttons">
            <NavLink to="/home/contactus">
              <button className="btn demo-btn">
                Request a Demo <img src="../images/enterprisepage/arwwhite.png" alt="" className="arw" />
              </button>
            </NavLink>
            <NavLink to="/home/contactus">
              <button className="btn contact-btn">
                Contact Us <img src="../images/enterprisepage/arwblack.png" alt="" className="arw" />
              </button>
            </NavLink>
          </div>
        </div>
      </div>

      {/* Organizations Section */}
      <div className="organisation">
        <div className="container">
          <div className="dash-image">
            <img src="../images/enterprisepage/dashboard.png" alt="" className="dashboard" />
          </div>
          <div className="section2-below">
            <h1 className="text1">
              Organizations Leading Sustainability with <span>Cero Hero</span>{" "}
            </h1>
            <p className="text2">
              Trusted by <span>20+</span> Companies
            </p>
            <h1 className="text3">Our Milestones & Trusted Partners</h1>
            <p className="text4">Celebrating our journey of impactful achievements and the esteemed companies that trust Cero Hero in their sustainability endeavors.</p>
          </div>

          {/* Swiper Slider */}
          <Swiper
            className="swiper-container"
            modules={[Navigation, Autoplay]}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            navigation
            spaceBetween={20}
            slidesPerView={2} // Default for screens smaller than 640px
            breakpoints={{
              640: { slidesPerView: 2 }, // Already defined
              768: { slidesPerView: 3 },
              1024: { slidesPerView: 5 },
            }}
          >
            {logos.map((logo, index) => (
              <SwiperSlide key={index}>
                <img src={logo} alt={`Company Logo ${index + 1}`} className="logo-image" />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="solution-overview">
        <img src="../images/enterprisepage/solution.png" alt="" className="solution-img" />
        <div className="container">
          <div className="overview-section">
            <div className="row">
              <div className="col-md-6 col-lg-5">
                <div className="overview-left">
                  <h2 className="overview-heading">Solution Overview</h2>
                  <p className="subheading">Driving Sustainability with Actionable Carbon Solutions</p>
                  <p className="description">
                    Cero Hero provides enterprises with a powerful climate-tech platform to measure, manage, and reduce their carbon footprints. From{" "}
                    <span>automated data collection to customizable ESG reporting,</span> our solution simplifies sustainability while fostering transparency and compliance. Together, we can achieve{" "}
                    <span>NET ZERO</span> through innovative tools and actionable insights.
                  </p>
                  <button className="btn learn-btn">
                    Learn How It Works <img src="../images/enterprisepage/arwwhite.png" alt="" className="arw" />
                  </button>
                </div>
              </div>
              <div className="col-md-7"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="top-features">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="feature-imgTop">
                <img src="../images/enterprisepage/feature.png" alt="" className="feature-imge" />
              </div>
            </div>
            <div className="col-md-8">
              <div className="feature-right">
                <h2 className="feature-heading">Top Features</h2>
                <p className="subheading">Empowering Enterprises to Lead the Net-Zero Revolution</p>
                <div className="features-grid">
                  <div className="feature">
                    <h3 className="number">01</h3>
                    <div className="right-content">
                      <p className="feature-text1">Real-Time Emission Tracking</p>
                      <p className="feature-text2">
                        Gain precision in tracking carbon emissions, covering <span>Scopes 1, 2, and 3.</span>
                      </p>
                    </div>
                  </div>
                  <div className="feature">
                    <h3 className="number">02</h3>
                    <div className="right-content">
                      <p className="feature-text1">Customizable ESG Reports</p>
                      <p className="feature-text2">
                        Tailor reports to meet global standards, ensuring compliance with <span>ESG frameworks.</span>
                      </p>
                    </div>
                  </div>
                  <div className="feature">
                    <h3 className="number">03</h3>
                    <div className="right-content">
                      <p className="feature-text1">Data-Driven Insights</p>
                      <p className="feature-text2">
                        Identify inefficiencies and opportunities for reduction with <span>AI-powered analytics.</span>
                      </p>
                    </div>
                  </div>
                  <div className="feature">
                    <h3 className="number">04</h3>
                    <div className="right-content">
                      <p className="feature-text1">Rewards for Sustainability</p>
                      <p className="feature-text2">
                        Earn <span>CERO Credits</span> for sustainable practices and redeem them in the exclusive marketplace.
                      </p>
                    </div>
                  </div>
                  <div className="feature">
                    <h3 className="number">05</h3>
                    <div className="right-content">
                      <p className="feature-text1">Plug-and-Play Integration</p>
                      <p className="feature-text2">Deploy Cero Hero seamlessly across your enterprise with minimal disruption.</p>
                    </div>
                  </div>
                  <button className="btn learn-btn">
                    Explore All Features <img src="../images/enterprisepage/arw2.png" alt="" className="arw" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="benefits-section">
        <div className="container">
          {/* <img src="../images/enterprisepage/hanging.png" alt="" className="hanging" /> */}
          <div className="benefit-data">
            <h1 className="benefit-title">Benefits</h1>
            <p className="transform">
              Redefine Sustainability Goals <br />
              Take advantage of a platform built for enterprises with these benefits:
            </p>
            <p className="ensure-txt">Optimize Operational Efficiency</p>
            <p className="ensure-data"> Reduce emissions while cutting operational costs.</p>
            <p className="ensure-txt">Enhance Stakeholder Confidence</p>
            <p className="ensure-data"> Showcase sustainability commitments through transparent reporting.</p>

            <p className="ensure-txt">Motivate Teams</p>
            <p className="ensure-data">Engage employees with gamified sustainability goals and rewards.</p>

            <p className="ensure-txt">Lead Industry Change</p>
            <p className="ensure-data">Position your enterprise as a climate action leader.</p>
            <NavLink to="/home/contactus">
              <button className="btn demo-btn">REQUEST A DEMO </button>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="interact-section">
        <div className="container">
          <div className="interact-content">
            <img src="../images/enterprisepage/interact.png" alt="interact_img" className="interact-img" />
            <div className="interact-text-content">
              <h1 className="interact-title">Interactive Section</h1>
              <p className="interact-qn">Calculate Your Impact How much can your organization save with Cero Hero?</p>
              <p className="interact-ans">Input key details and let our interactive calculator show you potential emission reductions and cost savings.</p>
              <NavLink to="/personal-carbon-footprint-calculator">
                <button className="btn learn-btn">
                  Try the Calculator <img src="../images/enterprisepage/arwwhite.png" alt="" className="arw" />
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className="testimonials-section container text-center">
        <h2 className="social-title">Social Proof</h2>
        <p className="subtitle">Trusted by Industry Leaders Cero Hero is transforming enterprises globally.</p>
        <p className="description">From corporate giants to innovative startups, organizations rely on us for seamless carbon tracking, ESG reporting, and sustainability solutions.</p>

        <h2 className="testimonal-title">Testimonials</h2>

        <div className="testimonials d-flex justify-content-center flex-wrap">
          {/* Testimonial 1 */}
          <div className="testimonial-card">
            <img src="../images/enterprisepage/comma.png" alt="" className="comma-imge" />
            <p className="quote">Cero Hero’s carbon accounting software transformed our reporting process, saving us hours of manual work.</p>
            <div className="author">
              <img src="../images/enterprisepage/profile1.png" alt="Guy Hawkins" className="avatar" />
              <div className="profile-card">
                <h5 className="user-name">Jessica Carter</h5>
                <p className="user-email">Sustainability Manager</p>
              </div>
            </div>
          </div>

          {/* Testimonial 2 */}
          <div className="testimonial-card">
            <img src="../images/enterprisepage/comma.png" alt="" className="comma-imge" />
            <p className="quote">We achieved regulatory compliance faster and with greater accuracy using their ESG reporting tool.</p>
            <div className="author">
              <img src="../images/enterprisepage/profile2.png" alt="Michael Turner" className="avatar" />
              <div className="profile-card">
                <h5 className="user-name">Michael Turner</h5>
                <p className="user-email">Compliance Officer</p>
              </div>
            </div>
          </div>

          {/* Testimonial 3 */}
          <div className="testimonial-card">
            <img src="../images/enterprisepage/comma.png" alt="" className="comma-imge" />
            <p className="quote">Cero Hero simplified our carbon footprint tracking while aligning perfectly with our sustainability goals. It’s a game-changer for ESG compliance.</p>
            <div className="author">
              <img src="../images/enterprisepage/profile3.png" alt="Emily Johnson" className="avatar" />
              <div className="profile-card">
                <h5 className="user-name">Emily Johnson</h5>
                <p className="user-email">Operations Head</p>
              </div>
            </div>
          </div>
          {/* Testimonial 4 */}
          <div className="testimonial-card">
            <img src="../images/enterprisepage/comma.png" alt="" className="comma-imge" />
            <p className="quote">The rewards system motivates our employees to engage with sustainability daily!</p>
            <div className="author">
              <img src="../images/enterprisepage/profile4.png" alt="David Lee" className="avatar" />
              <div className="profile-card">
                <h5 className="user-name">David Lee</h5>
                <p className="user-email">HR Director</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="enterprise-billing">
        <div className="container">
          <h1 className="enterprise-title">Enterprise Billing</h1>
          <p className="cutomise-txt">Customized pricing plans for enterprises of all sizes</p>
          <div className="row">
            {/* Basic Plan */}
            <div className="col-md-4 mb-3 mb-md-0">
              <div className="card  basic-plan">
                <h3 className="plan-title">Basic Plan</h3>
                <div className="card-body">
                  <div>
                    <p className="card-txts">For companies beginning their sustainability journey.</p>
                    <p className="features">Features:</p>
                    <ul>
                      <li>Up to 1000 metric tons of carbon tracking</li>
                      <li>Basic reporting tools</li>
                      <li>Standard compliance support</li>
                      <li>Email & phone support</li>
                    </ul>
                  </div>
                  <NavLink to="/home/contactus">
                    <button className="btn demo1">BOOK DEMO</button>
                  </NavLink>
                </div>
              </div>
            </div>

            {/* Advanced Enterprise Plan */}
            <div className="col-md-4 mb-3 mb-md-0">
              <div className="card advanced-plan">
                <h3 className="plan-title advance">Advanced Enterprise Plan</h3>
                <div className="card-body body2">
                  <div>
                    <p className="card-txts">For enterprises with higher carbon emissions and complex sustainability needs.</p>
                    <p className="features">Features:</p>
                    <ul>
                      <li>Up to 10000 metric tons of carbon tracking</li>
                      <li>Advanced real-time tracking and analytics</li>
                      <li>Customized reports for stakeholders</li>
                      <li>Dedicated compliance and regulatory support</li>
                      <li>API integration with existing enterprise systems</li>
                    </ul>
                  </div>
                  <NavLink to="/home/contactus">
                    <button className="btn demo2">BOOK DEMO</button>
                  </NavLink>
                </div>
              </div>
            </div>

            {/* Custom Plan */}
            <div className="col-md-4">
              <div className="card custom-plan">
                <h3 className="plan-title custom">Custom Plan</h3>
                <div className="card-body body3">
                  <div>
                    <p className="card-txts">For global enterprises with complex and unique requirements.</p>
                    <p className="features">Features:</p>
                    <ul>
                      <li>Unlimited carbon tracking</li>
                      <li>Advanced multi-level data analytics</li>
                      <li>Full customization of platform features</li>
                      <li>Dedicated sustainability experts</li>
                      <li>Comprehensive compliance services for global regulations</li>
                      <li>Full API and CRM integration</li>
                    </ul>
                  </div>
                  <NavLink to="/home/contactus">
                    <button className="btn demo3">BOOK DEMO</button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="steps-section">
        <div className="container">
          <h1 className="effort-title">Effortless Setup, Seamless Integration, Immediate Results</h1>

          {/* Step 1 */}
          <div className="step">
            <div className="step-number">STEP 1</div>
            <div className="step-content">
              <h3 className="step-subtitle">Easy Onboarding</h3>
              <p className="step-para">Explore the platform with our team to understand how it fits your enterprise needs.</p>
            </div>
            <div className="arrow-icon">
              <img src="../images/enterprisepage/arwblack.png" alt="" className="arrow-right" />
            </div>
          </div>

          {/* Step 2 */}
          <div className="step">
            <div className="step-number">STEP 2</div>
            <div className="step-content">
              <h3 className="step-subtitle">Track, Report, Improve</h3>
              <p className="step-para">Our implementation experts will guide you through the setup, integrating Cero Hero with your existing systems.</p>
            </div>
            <div className="arrow-icon">
              <img src="../images/enterprisepage/arwblack.png" alt="" className="arrow-right" />
            </div>
          </div>

          {/* Step 3 */}
          <div className="step">
            <div className="step-number">STEP 3</div>
            <div className="step-content">
              <h3 className="step-subtitle">Track, Report, Improve</h3>
              <p className="step-para">Get instant access to your carbon data, streamline ESG reporting, and make data-backed sustainability decisions.</p>
            </div>
            <div className="arrow-icon">
              <img src="../images/enterprisepage/arwblack.png" alt="" className="arrow-right" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enterprisepage;
