import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <Navbar expand="lg" className="custom-navbar" style={{ padding: "0.5rem 4rem" }}>
      {/* <Container> */}
      {/* Logo */}
      <Navbar.Brand href="/">
        <img src="../images/enterpriseheader/logoC.png" alt="Logo" className="logo" />
      </Navbar.Brand>
      {/* Toggler */}
      <Navbar.Toggle aria-controls="navbar-nav" />
      <Navbar.Collapse id="navbar-nav">
        <Nav className=" navbar-top">
          <div className="nav-top">
            <Nav.Link href="#resources">Solutions</Nav.Link>
            <Nav.Link href="#resources">Resources</Nav.Link>
            <Nav.Link href="#pricing">Pricing</Nav.Link>
            <Nav.Link href="#about">About Us</Nav.Link>
          </div>
          <div className="nav-bottom">
            <NavLink to="/home/contactus" className="btn me-2 contact-btn">
              Contact
            </NavLink>
            <NavLink to="/home/contactus" className="btn btn-success demo-btn">
              Demo
            </NavLink>
          </div>
        </Nav>
      </Navbar.Collapse>
      {/* </Container> */}
    </Navbar>
  );
};

export default Header;
