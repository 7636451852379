import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            {/* Logo Section */}

            {/* Product Links */}
            <div className="col-md-4 col-6 ">
              <div className="footer-links main">
                <div className="logo-image">
                  <img src="../images/enterprisefooter/flogo.png" className="logo-footer" alt="Cero Hero Logo" />
                </div>
                <div>
                  <div className="section-title">PRODUCT</div>
                  <ul className="links-list">
                    <li className="link-item">
                      <a href="">Features</a>
                    </li>
                    <li className="link-item">
                      <a href="">Pricing</a>
                    </li>
                    <li className="link-item">
                      <a href="">Resources</a>
                    </li>
                    <NavLink to="/home/contactus">
                      <li className="link-item">
                        <span>Contact Us</span>
                      </li>
                    </NavLink>
                  </ul>
                </div>
              </div>
            </div>

            {/* Company Links */}
            <div className="col-md-4  col-6">
              <div className="footer-links link2">
                <div className="section-title">COMPANY</div>
                <ul className="links-list">
                  <li className="link-item">
                    <a href="">Privacy Policy</a>
                  </li>
                  <li className="link-item">
                    <a href="">Terms of Service</a>
                  </li>
                  <li className="link-item">
                    <a href="">News</a>
                  </li>
                  <NavLink to="/home/contactus">
                    <li className="link-item">
                      <span>Contact Us</span>
                    </li>
                  </NavLink>
                </ul>
              </div>
            </div>

            {/* Office Section */}
            <div className="col-md-4 ">
              <div className="footer-office">
                <div className="section-title">Finland Office</div>
                <div className="office-detail">Otakaari 5, 02150 Espoo</div>
                <div className="office-detail">Finland</div>
                <div className="office-detail">VAT ID: 3437788-5</div>
              </div>
            </div>
          </div>

          {/* Footer Bottom */}
          <div className="footer-bottom">
            <div className="copyright">© 2024 CERO HERO</div>

            <div className="tagline">Carbon footprint calculator that makes sense</div>
            <div className="social-icons">
              <a href="" rel="noreferrer">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="" rel="noreferrer">
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a href="" rel="noreferrer">
                <i className="fab fa-youtube"></i>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
