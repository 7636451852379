import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import i18n from './i18n';
import CookieConsent from "react-cookie-consent";
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter i18n={i18n}>
    <CookieConsent
      location="bottom"
      buttonText="I ACCEPT"
      declineButtonText="I DECLINE"
      enableDeclineButton
      cookieName="Cerohero"
      margin="0px"
      style={{
        background: "linear-gradient(331deg, #6FE467 0%, #025F5C 100%)",
        borderRadius: "4px",
        padding: "5px 10px", // Using vw for responsive padding
        outline: "none",
        color: "white",
        margin: "0px",
        fontSize: "14px", // Using vw for responsive font size
        fontWeight: "500",
        border: "1px solid transparent",
        backgroundImage: "linear-gradient(331deg, #6FE467 0%, #025F5C 100%)",
        borderImage: "linear-gradient(331deg, #6FE467 0%, #025F5C 100%)",
        borderImageSlice: "1",
      }}
      buttonStyle={{
        padding: "5px 10px", // Using vw for responsive padding
        borderRadius: "4px",
        outline: "none",
        margin: "0px 5px",
        color: "white",
        fontSize: "14px", // Using vw for responsive font size
        fontWeight: "500",
        border: "1px solid transparent",
        backgroundColor: "#01480E",
        borderImageSlice: "1",
      }}
      declineButtonStyle={{
        padding: "5px 10px", // Using vw for responsive padding
        borderRadius: "4px",
        backgroundColor: "#d5d7da",
        outline: "none",
        border: "1px solid transparent", // Add border for visibility
        color: "#7c828d",
        fontSize: "14px", // Using vw for responsive font size
        fontWeight: "500",
        margin: "0px 5px",
      }}
      
      expires={150}
    >
      This website uses cookies to enhance the user experience.{" "}
      {/* <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
    </CookieConsent>

    <App />
  </BrowserRouter>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  
reportWebVitals();
