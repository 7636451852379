import React from 'react'
import { Outlet } from 'react-router-dom'
import Enterpriseheader from '../Enterpriseheader/Enterpriseheader'
import Enterprisefooter from '../Enterprisefooter/Enterprisefooter'


const Enterpriselayout = () => {
  return (
    <>
    <Enterpriseheader/>
    <Outlet />
    <Enterprisefooter/>
    </>
  )
}

export default Enterpriselayout